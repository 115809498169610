import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import routes from '../routes';
import superroutes from '../superroutes';
import { AuthContext } from '../../context/authcontext';

const AppRouter: React.FC = () => {
  const {isAuthenticated, isAdmin} = useContext(AuthContext); // Replace with your authentication logic

  if(!isAdmin)
  return (
    <Router>
      <Routes>
        {routes.map((route:any, index:any) => {
          const { path, component: Component, protected: isProtected } = route;
          const isAuthRequired = isProtected && !isAuthenticated;
          
          return (
            <Route
              key={index}
              path={path}
              element={isAuthRequired ? <Navigate to="/login" replace /> : <Component />}
            />
          );
        })}
        <Route path="*" element={<Navigate to="/notfound" replace />} />
      </Routes>
    </Router>
  );
  else
  return (
    <Router>
      <Routes>
        {superroutes.map((route:any, index:any) => {
          const { path, component: Component, protected: isProtected } = route;
          const isAuthRequired = isProtected && !isAuthenticated;
          
          return (
            <Route
              key={index}
              path={path}
              element={isAuthRequired ? <Navigate to="/login" replace /> : <Component />}
            />
          );
        })}
        <Route path="*" element={<Navigate to="/notfound" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
