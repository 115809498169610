import React, { useContext, useEffect, useRef, useState } from 'react';
import type { FormInstance, InputRef } from 'antd';
import { Button, Form, Input, Modal, Popconfirm, Table } from 'antd';
import { deleteAdmin, getAllAdmins, registerAdmin } from '../../../api/auth';
import { AuthContext } from '../../../context/authcontext';
import { Grid, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';


const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  id: React.Key;
  name: string;
  email: string;
  created_at: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const Admins: React.FC = () => {
  const [dataSource, setDataSource] = useState<DataType[]>([
  ]);
  const{user} = useContext(AuthContext);
  const [count, setCount] = useState(2);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleDelete = (id: string) => {
    deleteAdmin(user?.token, id).then((res: any) => {
        showSuccessMessage("Successfully deleted the user.")
        fetchAdmins()
    }).catch(err=> {
        showErrorMessage("Something went wrong. Please try later.")
    })
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      editable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_, record: any) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ];

  const fetchAdmins = () => {
    setLoading(true);
    getAllAdmins(user?.token).then((res:any) => {
        setLoading(false);
        setDataSource(res.allusers)
    }).catch(err=> {
        showErrorMessage("Something went wrong. Please try later.")
        setLoading(false);
    })
  }

  useEffect(() => {
    fetchAdmins()
  }, [])

  const handleAdd = () => {
    setModalShow(false);
    registerAdmin(email, name, password, 1, user?.token).then((res: any) => {
        if(res.message==="User registered successfully"){
            showSuccessMessage(res.message);
            fetchAdmins()
        }
    }).catch(err=> {
        showErrorMessage("Something went wrong. Please try later.")
    })
  };

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const showSuccessMessage = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
    });
  };

  const showErrorMessage = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
    });
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
        <ToastContainer />
      <Button onClick={()=> setModalShow(true)} type="primary" style={{ marginBottom: 16 }}>
        Add Admin
      </Button>
      <Table
        loading={loading}
        components={components}
        rowClassName={() => ''}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
      />
          <Modal
              title="Add Admin"
              centered
              open={modalShow}
              onOk={() => handleAdd()}
              onCancel={() => setModalShow(false)}
              width={1000}
          >
            <Grid item gap={4} display={'grid'}>
              <TextField size='small' onChange={(val)=> setEmail(val.target.value)} value={email} placeholder='Type email' />
              <TextField size='small' onChange={(val)=> setName(val.target.value)} value={name} placeholder='Type name' />
              <TextField type="password" size='small' onChange={(val)=> setPassword(val.target.value)} value={password} placeholder='Type password' />
            </Grid>
          </Modal>
    </div>
  );
};

export default Admins;
