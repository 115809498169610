import React, { useContext, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

import colors from "../../styles/colors";
import globals from "../../styles/globals";
import { AuthContext } from "../../context/authcontext";
import { IMAGES_URL } from "../../constants/constants";
import { Button, Col, InputNumber, Modal, Row, Slider } from "antd";
import IncreaseFont from '../../assets/lead assets/increase_size.png';
import DecreaseFont from '../../assets/lead assets/decrease_size.png';
import ResetFont from '../../assets/lead assets/reset.png';
// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: colors.dark,
    },
  },
});

// Custom styles
const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 90, // Adjust the height as needed
    justifyContent: "center",
    alignItems: "normal !important",
    backgroundColor: "#112123 !important"
  },
  profileContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImage: {
    width: '100%',
    height: 'auto',
    marginRight: "10px"
  },
  username: {
    marginRight: 1,
  },
  topFontBox: {
    padding: "10px 5px", 
    borderRadius: "6px",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px"
  }
}));

interface Props {
  login: boolean
}

const Navbar: React.FC<Props> = ({login}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const {logout, user, setSelectedCardIndex, largeFontSize, mediumFontSize, smallFontSize, xSmallFontSize, isAdmin, saveFonts} = useContext(AuthContext);
  const [inputValueLarge, setInputValueLarge] = useState<number | null>(largeFontSize);
  const [inputValueMedium, setInputValueMedium] = useState<number | null>(mediumFontSize);
  const [inputValueSmall, setInputValueSmall] = useState<number | null>(smallFontSize);
  const [inputValueXSmall, setInputValueXSmall] = useState<number | null>(xSmallFontSize);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  const handleMenuItemClick = (event: any) => {
    logout();
    setSelectedCardIndex(0);
    handleCloseMenu();
  };

  const onChangeLarge = (newValue: number | null) => {
    setInputValueLarge(newValue);
  };

  const onChangeMedium = (newValue: number | null) => {
    setInputValueMedium(newValue);
  };

  const onChangeSmall = (newValue: number | null) => {
    setInputValueSmall(newValue);
  };

  const onChangeXSmall = (newValue: number | null) => {
    setInputValueXSmall(newValue);
  };

  const handleMenuSettingsClick = (event: any) => {
    setOpenSettingsModal(true);
    handleCloseMenu();
  };

  const changeFont = (fontSize: number) => {
    if(largeFontSize!! < 50 || fontSize === -1)
    saveFonts(largeFontSize!!+fontSize, mediumFontSize!!+fontSize, smallFontSize!!+fontSize, xSmallFontSize!!+fontSize);
  }

  const onSave = () => {
    saveFonts(inputValueLarge!!, inputValueMedium!!, inputValueSmall!!, inputValueXSmall!!);
    setOpenSettingsModal(false)
  }
  const resetFont = () => {
    saveFonts(31, 24, 22, 20);
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" className={classes.appBar}>
        <style>{`
          :root {
            --font-size-large: ${largeFontSize}px;
            --font-size-medium: ${mediumFontSize}px;
            --font-size-small: ${smallFontSize}px;
            --font-size-extrasmall: ${xSmallFontSize}px;
          }

          .notMaterial {
            font-family: 'Source Sans Pro';
            font-weight: 400;
            line-height: 1.5;
          }

          .notMaterial p, .notMaterial li {
            font-size: var(--font-size-small);
          }
        `}</style>
        <Toolbar>
          <Box display="flex" alignItems="center" sx={{margin: login?"":"auto"}}>
            <img src={`${IMAGES_URL}Lead-Logo.png`} alt="Logo" className={classes.logoImage} />
            <Typography variant="body1" sx={{...globals.body.smallText, fontSize: smallFontSize?smallFontSize+4 : 20}}>
              lead@utah.edu
            </Typography>
          </Box>
          { login &&
          <Box className={classes.profileContainer}>
            <Button className={classes.topFontBox} onClick={resetFont}>
              <img alt="" height="30px" width="30px" src={ResetFont}/>
            </Button>
            <Button className={classes.topFontBox} onClick={() => changeFont(-1)}>
              <img alt="" height="20px" width="20px" src={DecreaseFont}/>
            </Button>
            <Button className={classes.topFontBox} onClick={() => changeFont(1)}>
              <img alt="" height="30px" width="30px" src={IncreaseFont}/>
            </Button>
            <Typography variant="body1" sx={{...globals.body.smallText, fontSize: smallFontSize?smallFontSize+4 : 20}}>
            {user?._doc.name}
            </Typography>
            <IconButton color="inherit" onClick={handleOpenMenu}>
              <img src={`${IMAGES_URL}arrow-down.png`} alt="arrow down"/>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleMenuItemClick}>Logout</MenuItem>
              {/* {!isAdmin && <MenuItem onClick={handleMenuSettingsClick}>Settings</MenuItem>} */}
            </Menu>
          </Box>
          }
        </Toolbar>
        <Modal
              title="Settings"
              centered
              open={openSettingsModal}
              okText="Save"
              onOk={() => onSave()}
              onCancel={() => setOpenSettingsModal(false)}
              width={1000}
            >
          <Row>
            <Col span={8}>
              <Typography sx={{ ...globals.heading.largeText, fontSize: `${inputValueLarge}px` }} variant="body1">Large Font Size</Typography>
            </Col>
            <Col span={12}>
              <Slider
                min={10}
                max={40}
                onChange={onChangeLarge}
                value={typeof inputValueLarge === 'number' ? inputValueLarge : 0}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={10}
                max={40}
                style={{ margin: '0 16px' }}
                value={inputValueLarge}
                onChange={onChangeLarge}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography sx={{ ...globals.heading.mediumText, fontSize: `${inputValueMedium}px` }} variant="body1">Medium Font Size</Typography>
            </Col>
            <Col span={12}>
              <Slider
                min={10}
                max={40}
                onChange={onChangeMedium}
                value={typeof inputValueMedium === 'number' ? inputValueMedium : 0}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={10}
                max={40}
                style={{ margin: '0 16px' }}
                value={inputValueMedium}
                onChange={onChangeMedium}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography sx={{ ...globals.heading.smallText, fontSize: `${inputValueSmall}px` }} variant="body1">Small Font Size</Typography>
            </Col>
            <Col span={12}>
              <Slider
                min={10}
                max={40}
                onChange={onChangeSmall}
                value={typeof inputValueSmall === 'number' ? inputValueSmall : 0}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={10}
                max={40}
                style={{ margin: '0 16px' }}
                value={inputValueSmall}
                onChange={onChangeSmall}
              />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography sx={{ ...globals.heading.xSmallText, fontSize: `${inputValueXSmall}px` }} variant="body1">XSmall Font Size</Typography>
            </Col>
            <Col span={12}>
              <Slider
                min={10}
                max={40}
                onChange={onChangeXSmall}
                value={typeof inputValueXSmall === 'number' ? inputValueXSmall : 0}
              />
            </Col>
            <Col span={4}>
              <InputNumber
                min={10}
                max={40}
                style={{ margin: '0 16px' }}
                value={inputValueXSmall}
                onChange={onChangeXSmall}
              />
            </Col>
          </Row>
          <Row>
            <Button onClick={resetFont}>Reset</Button>
          </Row>
        </Modal>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navbar;
