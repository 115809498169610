import React, { useContext,useState,useEffect } from 'react';
import { Card, CardMedia, CardContent, Typography, Button, Divider, Grid, Box } from '@mui/material';
import colors from '../../styles/colors';
import globals from '../../styles/globals';
import '../../styles/styles.css';
import { IMAGES_URL } from '../../constants/constants';
import { AuthContext } from '../../context/authcontext';

import styleClasses from './styles.module.css'

interface CustomCardProps {
    image: string;
    title: string;
    text: string;
    activityStatus: 'Completed' | 'Open' | 'Waiting' | 'Pending'
    activityType: 'Partner' | 'Solo';
    completeTaskData: {
      startDate?: string
    };
}

const styles = {
    card: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRadius: 2,
      },
      media: {
        height: 200,
        backgroundPosition: "0px -28px"
      },
      content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      divider: {
        marginTop: 2,
        marginBottom: 2,
      },
      bottomRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    }
}

const TaskCard: React.FC<CustomCardProps> = ({ image, title, text, activityType, activityStatus, completeTaskData }) => {
  const { setSelectedCardIndex, setSelectedData, user, smallFontSize, xSmallFontSize , mediumFontSize} = useContext(AuthContext);       

  const navigateToTask = () => {
    setSelectedCardIndex(1);
    setSelectedData({
        route: 'MyTasks',
        data: { currentTask: completeTaskData }
    });
  }

  const [cardSize, setCardSize] = useState(350);
  const [checkvalue, setcheckvalue] = useState(16);
  const [pad ,setPad]=useState(2)
  useEffect(() => {
    if (smallFontSize) {
      console.log("small font size :", smallFontSize)
      if (smallFontSize == 16) {
        setCardSize(40);
        setcheckvalue(smallFontSize)
        setPad(pad+3)
      }
      if (smallFontSize > checkvalue) {
        setCardSize(cardSize + 10);
        setcheckvalue(smallFontSize)
      } else {
        setCardSize(cardSize - 10);
        setcheckvalue(smallFontSize)
      }
    }
  }, [smallFontSize])
  useEffect(() => {

    const timer = setTimeout(() => {
      if (smallFontSize === 16) {
        console.log("Reset called :");
        setCardSize(40);
        // setCardWidth(300);
        setcheckvalue(smallFontSize);
      }
    }, 1000);

    // Clean up the timeout if the component unmounts or the effect dependencies change
    return () => clearTimeout(timer);

  }, [smallFontSize]);

  const taskHasOpened = activityStatus === 'Completed' || activityStatus === 'Open'


  const buttonProps = activityStatus === 'Open'
    ? {variant: 'contained', sx: {...globals.button, fontSize: xSmallFontSize, padding: '6px' }, children: ''}
    : {variant: 'h6', sx: {...globals.heading.smallText, fontSize: smallFontSize!!, color: activityStatus === 'Completed' ? colors.aqua : activityStatus === 'Waiting' ? colors.gold : 'red', fontWeight: 700}, children: ''}

  buttonProps.children = activityStatus === 'Completed' ? 'Completed' :
    activityStatus === 'Open' ? 'Open' :
    activityStatus === 'Waiting' ? 'Opens soon' :
      'Not available'

  const cardOnClick = taskHasOpened ? navigateToTask : undefined
  const cardClassName = taskHasOpened ? [styleClasses.activityCard] : [styleClasses.activityCard, styleClasses.closed]

  const openText = (!taskHasOpened && completeTaskData.startDate) ? (new Date(completeTaskData.startDate)).toLocaleDateString('en-US', {weekday: 'long', month: 'long', day: 'numeric'}) : null

  return (
    <Card onClick={cardOnClick} className={cardClassName.join(' ')} sx={{...styles.card, border: `1px solid ${colors.aqua}`, borderColor: activityStatus === 'Completed' ? colors.aqua : activityStatus === 'Pending' ? colors.error : colors.gold }} >
      <CardMedia sx={{...styles.media, backgroundPosition: title==="Activity 2" || title==="Activity 3"?"0px -95px": title==="Activity 1" ? "0px -10px" : "0px -28px"}} image={image} title="Image" />
      <CardContent sx={styles.content}>
        <Box>
            <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" >
                <Typography variant="h6" style={{...globals.heading.smallText, fontSize: smallFontSize!!, fontWeight: 700}}>
                    {title}
                </Typography>
                <Typography {...(buttonProps as any)}/>
            </Grid>
            <Typography className='ellipsis-3' variant="body2" mt={2} style={{...globals.body.mediumText,  paddingBottom:`${pad}px`,fontSize: mediumFontSize!!}}>{title !== "Activity 2" ? text : user?._doc.userType === 1 ? "Complete the LEAD Guide for yourself. Do not discuss your answers with your activity partner.": "Complete the LEAD Guide from the perspective of the patient. Do not discuss your answers with your activity partner"}</Typography>
            {openText && <Typography className='ellipsis-3' variant="body2" mt={2} style={{...globals.body.mediumText,  paddingBottom:`${pad}px`,fontSize: mediumFontSize!!}}>
              Activity opens on {openText}
            </Typography>}
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={styles.bottomRow}>
            {activityType === 'Solo' ? (
              <img src={`${IMAGES_URL}MyDocumentsImages/Solo.png`} alt={activityType} width={24} height={24} />
            ) : (
              <img src={`${IMAGES_URL}MyDocumentsImages/Partnered.png`} alt={activityType} width={24} height={24} />
            )}
            <Typography variant="body2" style={{...globals.heading.mediumText, fontSize: mediumFontSize!!, marginLeft: 10}}>{activityType} activity</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TaskCard;
