import React, { useContext, useEffect, useState } from 'react';
import { Box, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from '@mui/material';
import globals from '../../../styles/globals';
import Pagination from '../../pagination';
import Task from '../../Tasks';
import '../../../styles/styles.css';
import RecommendedList from '../../RecommendedList';
import { IMAGES_URL } from '../../../constants/constants';
import { getAllUserTasks } from '../../../api/auth';
import { AuthContext } from '../../../context/authcontext';
import Loader from '../../Loader';
import ReadSpeakerComponent from '../../ReadSpeaker';
import colors from '../../../styles/colors';
import { useNavigate } from 'react-router-dom';
import TaskCard from '../../TaskCard';
import Act1 from '../../../assets/lead assets/Act1.jpg';
import Act2 from '../../../assets/lead assets/Act2.jpg';
import Act3 from '../../../assets/lead assets/Act3.jpg';
import Act4 from '../../../assets/lead assets/Act4.png';
import DashboardLogo from '../../../assets/lead assets/dashboard_logo.png';

const DashboardContent: React.FC = () => {

  const styles = {
    container: {
      mt: 10,
      mb: 10,
      width: "100%"
    }
  }

  const { user, logout, setTasksGlobalData, setSelectedCardIndex, setSelectedData, isAuthenticated, setSelectedUser, selectedUser, largeFontSize, mediumFontSize, activityDone, setActivityDone } = useContext(AuthContext);
  const [progressData, setProgressData] = useState<any>([]);
  const [resourcesBlurbs, setResourcesBlurbs] = useState<any>([
    [
      { link: "https://www.youtube.com/watch?v=Eq_Er-tqPsA", linkLabel: "Understand Alzheimer’s disease: ", desc: "This video describes the cause of Alzheimer’s disease and how it progresses through the brain.", img: `${IMAGES_URL}recommended1.png` },
      { link: "", linkLabel: "Understanding different types of dementia: ", desc: "This infographic compares four different types of dementia and how they affect the brain.", img: `${IMAGES_URL}recommended1.png` },
      { link: "", linkLabel: "5 Myths about advance care planning: ", desc: "This infographic explains common misconceptions about advance care planning.", img: `${IMAGES_URL}recommended1.png` },
    ],
    [
      { link: "https://www.hopkinsmedicine.org/health/conditions-and-diseases/alzheimers-disease/stages-of-alzheimer-disease", linkLabel: "Stages of Alzheimer’s disease: ", desc: "This article explains what to expect at each stage of Alzheimer’s disease.", img: `${IMAGES_URL}recommended2.png` },
      { link: "", linkLabel: "Next steps after Alzheimer’s diagnosis: ", desc: "This infographic provides suggestions and resources to help plan for life with Alzheimer’s disease.", img: `${IMAGES_URL}recommended2.png` },
      { link: "https://www.nia.nih.gov/health/getting-your-affairs-order-checklist-documents-prepare-future?utm_source=nia-eblast&utm_medium=email&utm_campaign=caregiving-2023019#important-papers", linkLabel: "Documents to prepare for the future: ", desc: "This website helps you get your legal affairs in order and lists other important documents and care details.", img: `${IMAGES_URL}recommended2.png` },
    ],
    [
      { link: "https://www.nia.nih.gov/health/alzheimers-caregiving-changes-communication-skills", linkLabel: "Changes in communication skills: ", desc: "This article explains common communications issues in Alzheimer’s disease and how care partners can help.", img: `${IMAGES_URL}recommended3.png` },
      { link: "https://www.nia.nih.gov/health/making-decisions-someone-end-life?utm_source=nia-eblast&utm_medium=email&utm_campaign=caregiving-20220407", linkLabel: "Making decisions for someone else: ", desc: "This article offers guidance for caregivers on making end-of-life decisions.", img: `${IMAGES_URL}recommended3.png` },
      { link: "https://www.alzheimers.gov/life-with-dementia/planning-for-future?utm_source=nia-eblast&utm_medium=email&utm_campaign=alzgov-20220412#financial-planning", linkLabel: "Life with dementia: ", desc: "This website defines legal documents, describes medical decisions, and explains advance care planning documents.", img: `${IMAGES_URL}recommended3.png` },
    ],
    [
      { link: "https://www.compassionandchoices.org/docs/default-source/default-document-library/dementia-provision-only-final-6-29-20-pdf.pdf?sfvrsn=2aebdcb5_2", linkLabel: "Dementia Provision (Advance Directive Addendum): ", desc: "This addendum is for those with Alzheimer’s disease or another type of dementia.", img: `${IMAGES_URL}recommended4.png` },
      { link: "https://www.alz.org/help-support/resources/alznavigator", linkLabel: "Alzheimer’s Navigator: ", desc: "This resource helps build an action plan for all stages of Alzheimer’s disease.", img: `${IMAGES_URL}recommended4.png` },
      { link: "https://www.nia.nih.gov/health/choosing-health-care-proxy", linkLabel: "Choosing a health care proxy: ", desc: "This article details how to choose someone to make health care decisions on your behalf.", img: `${IMAGES_URL}recommended4.png` },
    ]
  ]);
  const [currentTask, setCurrentTask] = useState(-1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedUser(event.target.value as string);
  };

  useEffect(() => {
    // Check if the user is authenticated and the token is valid
    if (!isAuthenticated) {
      navigate("/login"); // Redirect to the login page if not authenticated
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (activityDone) {
      setModalOpenAct(true)
    }
  }, [activityDone])

  useEffect(() => {
    const fetchData = async () => {

      if (!localStorage.getItem("showModal"))
        setModalOpen(true);
      setLoading(true);

      getAllUserTasks(selectedUser, user?.token).then((res: any) => {
        setLoading(false);
        if (res) {
          const meeting_status = localStorage.getItem('move')
          if(meeting_status){
            setSelectedCardIndex(1);
            setSelectedData({
                route: 'MyTasks',
                data: { currentTask: res?.tasks[2] }
            });
          }
          const progress = res?.tasks.map((task : any) => ({
            ...task,
            activityStatus: task.progress === 1 ? 'Completed' :
              (task.currentTask === 0 || !task.startDate) ? 'Pending' :
              (new Date(task.startDate)).getTime() - Date.now() < 0 ? 'Open'
                : 'Waiting'
          }));
          const cur = res?.tasks?.findIndex((item: any) => item?.currentTask === 1);
          setProgressData(progress);
          const linkedClickedValue = localStorage.getItem('linkedclicked');
          if (linkedClickedValue) {
              setSelectedData(null);
              localStorage.removeItem('linkedclicked');
              setSelectedCardIndex(3);
          }
          setCurrentTask(cur);
          if (progress.find((task: any) => task.userId === user?._doc._id))
            setTasksGlobalData({
              progressData: progress,
              currentTask: cur
            });
        }
      }, err => {
        if (err?.response?.status === 403) {
          logout();
        }

      })
    }

    fetchData()
  }, [selectedUser, user]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalOpenAct, setModalOpenAct] = useState<boolean>(false);
  const allImages = [
    Act1,
    Act2,
    Act3,
    Act4,

  ]

  const handleActivity = () => {
    setModalOpenAct(false);
    setActivityDone(false);
  };
  const handleClose = () => {
    localStorage.setItem("showModal", "false");
    setModalOpen(false);
  };
  const [cardSize, setCardSize] = useState(40);
  const [dashboardLogoWidth, setDashboardLogoWidth] = useState(390);
  const [dashboardLogoHeight, setDashboardLogoHeight] = useState(70);
  const [checkvalue, setcheckvalue] = useState(25);

  useEffect(() => {
    if (largeFontSize) {
      if (largeFontSize === 25) {
        setCardSize(40);
        setDashboardLogoWidth(390)
        setDashboardLogoHeight(70)
        setcheckvalue(largeFontSize)
      }
      if (largeFontSize > checkvalue) {
        setCardSize(cardSize + 10);
        setDashboardLogoWidth((prev: number) => prev+10)
        setDashboardLogoHeight((prev: number) => prev+2)
        setcheckvalue(largeFontSize)
      } else {
        setCardSize(cardSize - 10);
        setDashboardLogoWidth((prev: number) => prev-10)
        setDashboardLogoHeight((prev: number) => prev-2)
        setcheckvalue(largeFontSize)
      }
    }
  }, [largeFontSize])
  useEffect(() => {
    
    const timer = setTimeout(() => {
      if (largeFontSize === 25) {
        setCardSize(40);
        // setCardWidth(300);
        setcheckvalue(largeFontSize);
      }
    }, 1000);

    // Clean up the timeout if the component unmounts or the effect dependencies change
    return () => clearTimeout(timer);

  }, [largeFontSize]);

  if (loading)
    return (
      <Box sx={{ marginTop: "120px" }}>
        <Loader />

      </Box>
    )

  return (
    <Grid sx={styles.container}>
      <Box sx={{ position: "relative" }}>

        {user?._doc?.userType === 1 ? (null) : (
          <Box sx={{ width: '40%', margin: 'auto auto 40px auto' }}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedUser}
                onChange={handleChange}
              >
                <MenuItem key={user?._doc?._id} value={user?._doc?._id}>
                  {user?._doc?.name}
                </MenuItem>
                {user?._doc?.userAssociated.map((item: any) => (
                  <MenuItem key={item.userId} value={item.userId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <Box style={{  textAlign: "center", marginBottom: "30px" }}>
        {/* <Box sx={{ ...globals.card }} style={{ height: `${cardSize}px` }}> */}
          {/* <Box sx={{ ...globals['card::after'] }}></Box>
          <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, color: "white" }} variant="body1">Current Progress</Typography>
          <Box sx={{ ...globals['card::before'] }}></Box> */}
              <img src={DashboardLogo} alt={"DashboardLogo"} width={largeFontSize!!*12.5} height={largeFontSize!!+45} />

        </Box>
        <Box sx={{ ...globals.taskPagination }}>
          <Pagination cardSelected={setCurrentTask} progress={progressData} count={[1, 2, 3, 4, 5, 6, 7]} completed={[1, 2]} current={currentTask} />
        </Box>

        {progressData[currentTask]?.taskFormFields.length > 0 ? (
          <>
            <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: colors.black, textAlign: "center", marginTop: 6, textDecoration: 'underline', cursor: 'pointer' }} variant="body1"
              onClick={() => {
                setSelectedCardIndex(1);
                setSelectedData({
                  route: 'MyTasks',
                  previousSubmissions: true,
                  data: { currentTask: progressData[currentTask] }
                });
              }}
            >
              View Previous Submissions
            </Typography>
          </>
        ) : null}

        <Grid container mt="0px" sx={{ margin: "auto", display: 'flex', flexWrap: 'wrap', alignItems: 'stretch' }} spacing={4}>
          {progressData && progressData.map((item: any, index: number) => (
            <Grid item key={index + 1} xs={12} sm={6} sx={{ display: 'flex' }}>
              <Box mt={5} sx={{ flex: 1 }} >
                <TaskCard
                  image={`${allImages[index]}`}
                  title={`Activity ${index + 1}`}
                  text={item.taskInstructions}
                  activityType={index === 2 ? 'Partner' : 'Solo'}
                  activityStatus={progressData.slice(0, 4)[index].activityStatus}
                  completeTaskData={progressData.slice(0, 4)[index]}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        <div style={{textAlign:'center', textDecorationLine:'underline', cursor:'pointer', marginTop:'20px' , color:'blue', marginLeft: "60px" }} onClick={()=>{setModalOpen(true)}}>Welcome Message</div>
        {/* {selectedUser !== user?._doc?._id && progressData[currentTask]?.currentTask === 1 ? (
          <Typography sx={{...globals.heading.mediumText, fontSize: mediumFontSize, textAlign: "center", marginTop: "20px"}}>Only Care Taker can access it!</Typography>
          ) :
          currentTask === -1 ?
          <Typography sx={{...globals.heading.mediumText, fontSize: mediumFontSize, textAlign: "center", marginTop: "20px"}}>All Tasks Are Completed!</Typography>
          :
        (
          <Task data={progressData[currentTask]} />
        )} */}

      </Box>
      {/* <Divider sx={{ marginTop: 8 }} />
      <Typography sx={{ ...globals.heading.mediumText, fontSize: mediumFontSize, color: "black", textAlign:"center", marginY: 4 }} variant="body1">Recommended Resources</Typography>
      { currentTask > -1 &&
      <RecommendedList 
      data={resourcesBlurbs[currentTask]}/>
      } */}
      <Modal sx={{ width: "70%", margin: "auto", display: "grid", border: "none", maxHeight: window.innerHeight * 0.9, overflowY: "scroll" }} open={modalOpen} >
        <Grid sx={{ background: "white", paddingY: 15, paddingX: 5 }} >
          <ReadSpeakerComponent readId='readspeaker_modal-content' readClass='' compId="modal-content" />
          <Grid id="modal-content">
            <Grid item md={4}>
              <Typography sx={{ ...globals.heading.largeText, fontSize: largeFontSize, textAlign: "center", marginBottom: "20px" }}>Welcome to the LEAD Program</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography sx={{ ...globals.body.mediumText, fontSize: largeFontSize, whiteSpace: "break-spaces", fontWeight: "normal" }}>{"Thank you for participating  in the LEAD Project! LEAD stands for “Life-Planning in Early Alzheimer’s and Other Dementias.”\n\nAs dementia progresses, difficult health care decisions increasingly fall to care partners. Care partners usually want to follow the patient’s wishes, but may not have talked about these decisions with the patient beforehand. This can lead to stress, anxiety, and guilt.\n\nThe LEAD Guide is a resource to guide families through difficult conversations. We will provide you with resources and tools to engage in conversations about long-term care and end-of-life care. These conversations will help care partners understand patients’ values and preferences when making decisions on their behalf and ensure that the patient receives the care that they desire.\n\nUsing this website, you and your activity partner will be guided through four educational activities. You’ll complete the LEAD Guide, engage in conversations about advance care planning, and complete a medical advance directive. Patients can share these documents with family members and healthcare providers.\n\nIf you have any questions, please contact the project team at lead@utah.edu or 801-587-7353."}</Typography>
            </Grid>
            <Grid item md={4}>
              <Card className='cursor' onClick={handleClose} sx={{ ...globals.taskBtn, float: "right", marginTop: 10, marginBottom: 0 }}>
                <Typography sx={{ ...globals.taskBtnText, fontSize: mediumFontSize }}>Close</Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal sx={{ width: "70%", margin: "auto", display: "grid", border: "none", maxHeight: window.innerHeight * 0.9, overflowY: "scroll" }} open={modalOpenAct} onClose={handleActivity}>
        <Grid sx={{ background: "white", paddingY: 5, paddingX: 5, position: "relative" }} >
          {/* <ReadSpeakerComponent readId='readspeaker_modal-content' readClass='' compId="modal-content"/> */}
          <Grid id="modal-content">
            <div style={{ position: "relative", paddingTop: "50.25%" }}><iframe src="https://share.descript.com/embed/aMP5xXsY54F" style={{ border: "1px solid", position: "absolute", top: 0, height: "100%", width: "100%" }} ></iframe></div>

            <Grid item md={4}>
              <Card className='cursor' onClick={handleActivity} sx={{ ...globals.taskBtn, right: 40, position: "absolute", bottom: "10px" }}>
                <Typography sx={{ ...globals.taskBtnText, fontSize: mediumFontSize }}>Close</Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default DashboardContent;
